$l-device: 1200px;
$m-device: 1170px;
$s-device: 760px;
$ss-device: 320px;

@mixin responsive($size) {
  @if $size == ss {
    @media only screen and (max-width: $ss-device) {
      @content;
    }
  } @else if $size == s {
    @media only screen and (max-width: $s-device) {
      @content;
    }
  } @else if $size == m {
    @media only screen and (min-width: $s-device + 1) and (max-width: $m-device) {
      @content;
    }
  } @else if $size == l {
    @media only screen and (min-width: $m-device + 1) {
      @content;
    }
  }
}
