@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?tbmemq');
  src: url('fonts/icomoon.eot?tbmemq#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?tbmemq') format('truetype'),
    url('fonts/icomoon.woff?tbmemq') format('woff'), url('fonts/icomoon.svg?tbmemq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-controladora:before {
  content: '\e913';
}
.icon-controlada:before {
  content: '\e914';
}
.icon-coligada:before {
  content: '\e915';
}

.icon-double-bullets:before {
  content: '\e904';
}
.icon-arrow-red:before {
  content: '\e903';
}
.icon-arrow-right:before {
  content: '\e902';
}
.icon-status-icon:before {
  content: '\e93d';
}
.icon-progress-bar:before {
  content: '\e93e';
}
.icon-icon-bullets:before {
  content: '\e93f';
}
.icon-arrow-up:before {
  content: '\e900';
}
.icon-business:before {
  content: '\e901';
}
.icon-case:before {
  content: '\e905';
}
.icon-check:before {
  content: '\e906';
}
.icon-clips:before {
  content: '\e907';
}
.icon-document:before {
  content: '\e908';
}
.icon-down:before {
  content: '\e909';
}
.icon-download:before {
  content: '\e90a';
}
.icon-eye:before {
  content: '\e90b';
}
.icon-flag:before {
  content: '\e90c';
}
.icon-fly:before {
  content: '\e90d';
}
.icon-Group-339:before {
  content: '\e90e';
}

.icon-home:before {
  content: '\e90f';
}

.icon-letter:before {
  content: '\e910';
}
.icon-lock:before {
  content: '\e911';
}
.icon-lock2:before {
  content: '\e912';
}
.icon-menu-plus:before {
  content: '\e916';
}
.icon-oval:before {
  content: '\e917';
}
.icon-oval-close:before {
  content: '\e918';
}
.icon-Oval-Copy-9:before {
  content: '\e919';
}

.icon-people:before {
  content: '\e91a';
}
.icon-person:before {
  content: '\e91b';
}
.icon-person-plus:before {
  content: '\e91c';
}
.icon-reload:before {
  content: '\e91d';
}
.icon-shield:before {
  content: '\e91e';
}
.icon-tag:before {
  content: '\e920';
}
.icon-timer:before {
  content: '\e921';
}
.icon-Union:before {
  content: '\e922';
}
.icon-up:before {
  content: '\e923';
}
.icon-write:before {
  content: '\e924';
}
.icon-calendar:before {
  content: '\e925';
}
.icon-perfil:before {
  content: '\e926';
}
.icon-lupa:before {
  content: '\e927';
}
.icon-notification:before {
  content: '\e929';
}
.icon-lixo:before {
  content: '\e92a';
}
.icon-select:before {
  content: '\e92b';
}
.icon-lapis:before {
  content: '\e92c';
}
.icon-error:before {
  content: '\e93b';
}
.icon-inativo:before {
  content: '\e93c';
}
