@import './icon-fonts.scss';

@font-face {
  font-family: 'HelveticaNeue';
  src: url(./fonts/HelveticaNeue-Bold.otf);
  font-weight: 600;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url(./fonts/HelveticaNeue-Medium.otf);
  font-weight: 500;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url(./fonts/HelveticaNeue-Regular.otf);
  font-weight: 400;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url(./fonts/HelveticaNeue-Light.otf);
  font-weight: 300;
}

// Frísia fonts
@font-face {
    font-family: 'Montserrat-Bold';
    src: url(./fonts/Montserrat-Bold.woff);
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url(./fonts/Montserrat-Medium.woff);
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url(./fonts/Montserrat-Regular.woff);
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url(./fonts/Montserrat-SemiBold.woff);
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url(./fonts/Poppins-Regular.woff);
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url(./fonts/Poppins-SemiBold.woff);
}

@font-face {
    font-family: 'arial-bold';
    src: url(./fonts/arial-bold.woff);
}
