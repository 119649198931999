.custom-alert-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-alert {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto; /* Permitir rolagem em casos de conteúdo excessivo */
  text-align: center;
  box-sizing: border-box;
}

.custom-alert p {
  margin: 0 0 16px;
  word-wrap: break-word;
}

.custom-alert button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px;
}

.custom-alert button:hover {
  background-color: #0056b3;
}
